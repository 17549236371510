import React from "react"

import Layout from "../../components/layout"
import SEO from "../../components/seo"
import imgAboutCover from "../../assets/images/mission/about-emm/about-emm-cover.png"
import imgAboutSectionOne from "../../assets/images/home/home-section-1.png"
import imgPeopleOne from "../../assets/images/mission/about-emm/people-img-1.png"
import imgPeopleTwo from "../../assets/images/mission/about-emm/people-img-2.png"
import imgPeopleThree from "../../assets/images/mission/about-emm/people-img-3.png"

const AboutEmmPage = () => (
  <Layout>
    <SEO title="About EMM" />
    <main>
      <div className="uk-cover-container" data-uk-height-viewport="offset-top: true; offset-bottom: 40">
        <img src={imgAboutCover} width="1440" height="535" alt="" data-uk-cover />
        <div className="uk-overlay uk-position-cover header-overlay"></div>
      </div>
      <section id="about-emm" style={{marginTop: "-100px", position: "relative"}}>
          <div className="large-container pb6">
            <div className="standard-container standard-bg">
              <div className="flex flex-column flex-row-ns">
                <div className="w-100 w-60-ns pr5-ns pb5-ns z-index-10">
                <div>
                  <h2 className="relative">
                  Emirates Mars Mission Overview
                    <div className="text-underline-heavy"></div>
                  </h2>
                  <p className="mt5">
                  The Emirates Mars Mission (EMM) ​is the United Arab Emirates’ (UAE) ​first mission to
                  Mars. EMM is designed to ​orbit Mars and study the dynamics in ​the Martian atmosphere
                  on a global scale, and on both diurnal and seasonal timescales. Using three scientific
                  instruments on board of the spacecraft, EMM will provide a set of measurements
                  fundamental to an improved understanding of ​circulation and weather in the Martian
                  lower and middle atmosphere. Combining such data with the monitoring of the upper
                  layers of the atmosphere, EMM measurements will reveal the mechanisms behind the
                  upward transport of energy and particles, and the subsequent escape of atmospheric
                  particles from the gravity of Mars.
                  </p>
                </div>
              </div>
                <div className="w-100 w-40-ns">
                <div>
                  <div className="animated-image-container">
                      <img src={imgAboutSectionOne} alt=""/>
                  </div>
                </div>
              </div>  
                </div>
                {/* <div className="mv2">
                  <h3>
                    HOPE PROBE ON A MISSION
                  </h3>
                  <p className="mt4">
                    The Emirates Mars Mission (EMM) will explore the dynamics in the atmosphere of Mars on a global scale while sampling contemporaneously both diurnal and seasonal timescales. Using three science instruments on an orbiting spacecraft, EMM will provide a set of measurements fundamental to an improved understanding of circulation and weather in the Martian lower and middle atmosphere. Combining such data with the monitoring of the upper layers of the atmosphere, EMM measurements will reveal the mechanisms behind the upward transport of energy and particles and the subsequent escape of atmospheric particles from the gravity of Mars. The unique combination of instrumental synergy and temporal and spatial coverage of Mars’ different atmospheric layers will open a new and much-needed window into the workings of the atmosphere of our planetary neighbours.
                  </p>
                </div> */}
              <div className="custom-slider mv5" data-uk-slider>

                  <div className="uk-position-relative">

                      <div className="uk-slider-container uk-light">
                          <ul className="uk-slider-items">
                              <li>
                                  <img src={imgPeopleOne} alt=""/>
                              </li>
                              <li>
                                  <img src={imgPeopleTwo} alt=""/>
                              </li>
                              <li>
                                  <img src={imgPeopleThree} alt=""/>
                              </li>
                          </ul>
                      </div>

                      <div className="uk-hidden@s uk-light">
                      <a className="uk-position-center-left-out uk-position-small" href="#" data-uk-slidenav-previous data-uk-slider-item="previous"></a>
                          <a className="uk-position-center-right-out uk-position-small" href="#" data-uk-slidenav-next data-uk-slider-item="next"></a>
                      </div>

                      <div className="uk-visible@s">
                          <a className="uk-position-center-left-out uk-position-small" href="#" data-uk-slidenav-previous data-uk-slider-item="previous"></a>
                          <a className="uk-position-center-right-out uk-position-small" href="#" data-uk-slidenav-next data-uk-slider-item="next"></a>
                      </div>

                  </div>

                  <ul className="uk-slider-nav uk-dotnav uk-flex-center uk-margin"></ul>
                </div>

                <div className="mv2">
                  <h3>
                    JOINING GLOBAL EFFORTS
                  </h3>
                  <p className="mt4">
                    The Mars Exploration Program Advisory Group (MEPAG) is a central forum through which consensus is reached among the international Mars science community about the scientific goals and investigations that should be pursued in order to further humanity’s knowledge of Mars. The 2020 MEPAG Goals Document outlines four primary goals: I) Determine if Mars ever supported life, or still supports, life, II) Understand the processes and history of climate on Mars, III) Understand the origin and evolution of Mars as a geological system and IV) Prepare for human exploration. Each of these high-level goals is flowed down to a series of objectives, each of which are in turn flowed down to one or more sub-objectives and recommended investigations.
                  </p>
                  <p>
                  The EMM science objectives and investigations are primarily relevant to MEPAG Goal II: Understand the processes and history of climate on Mars. Specifically, by studying the lower and upper atmosphere simultaneously, all four EMM investigations addresses MEPAG Objective II.A: "Characterize the state and controlling processes of the present-day climate of Mars under the current orbital configuration."
                  </p>
                </div>
            </div>
            <div className="standard-container standard-bg flex flex-column flex-row-ns">
                  <div className="w-100 w-40-ns">
                      <div className="animated-image-container">
                          <img src={imgAboutSectionOne} alt=""/>
                      </div>
                  </div>
                  <div className="w-100 w-60-ns pr5-ns pb5-ns z-index-10">
                    <div className="negative-margin-right-150 pl5-ns pt5-ns">
                      <h3>
                        OBJECTIVES
                      </h3>
                      <p className="uk-text-bold text-light">
                        The 
                        <span className="text-highlight-red"> First Complete Picture </span>
                        of the Martian Atmosphere.
                      </p>
                      <div className="flex flex-column mv4">
                        <div className="stacked-card flex justify-between items-center">
                          <div className="w-20 flex justify-center items-center">
                            <span className="uk-text-lead text-highlight-brown stacked-card-count">
                              01
                            </span>
                          </div>
                          <div className="w-80">
                            <p>
                            Understand climate dynamics and the global weather map through characterizing the lower atmosphere of Mars.
                            </p>
                          </div>
                        </div>
                        <div className="stacked-card flex justify-between items-center">
                          <div className="w-20 flex justify-center items-center">
                            <span className="uk-text-lead text-highlight-brown stacked-card-count">
                              02
                            </span>
                          </div>
                          <div className="w-80">
                            <p>
                            Explain how the weather changes the escape of Hydrogen and Oxygen through correlating the lower atmosphere conditions with the upper atmosphere.
                            </p>
                          </div>
                        </div>
                        <div className="stacked-card flex justify-between items-center">
                          <div className="w-20 flex justify-center items-center">
                            <span className="uk-text-lead text-highlight-brown stacked-card-count">
                              03
                            </span>
                          </div>
                          <div className="w-80">
                            <p>
                            Understand the structure and variability of Hydrogen and Oxygen in the upper atmosphere, as well as identifying why Mars is losing them into space.
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
          
          </div>  
      </section>
    </main>
  </Layout>
)

export default AboutEmmPage
